.content {
  margin-top: 66px;
  display: flex;
  width: 1180px;
  flex-direction: column;
}

.title {
  font-weight: 400;
  font-size: 68px;
  line-height: 81.6px;
  text-transform: uppercase;
  max-width: 1064px;
  color: var(--black2);
  text-shadow: -1px 0 var(--white1), 0 1px var(--white1), 1px 0 var(--white1), 0 -1px var(--white1);
}

.lines {
  margin-top: 56px;
  font-size: 16px;
  line-height: 25.6px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
}

.line {
  max-width: 540px;
}

.line:not(:first-child), .linkLine {
  margin-top: 30px;
}

.linkLine {
  display: flex;
  flex-wrap: wrap;
}

.link {
  border-bottom: 1px solid var(--orange1);
  display: flex;
  white-space: nowrap;
}

@media screen and (max-width: 1250px) {
  .content {
    width: 100%;
  }
}

@media screen and (max-width: 604px) {
  .linkLine {
    row-gap: 15px;
  }
}

@media screen and (max-width: 500px) {
  .title {
    font-size: 48px;
    line-height: 60px;
  }
  .text {
    white-space: normal;
    word-break: normal;
  }
}
