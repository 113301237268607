.footer {
  width: 1180px;
  padding: 16px 0;
}

.text {
  font-size: 12px;
  opacity: 0.3;
}

@media screen and (max-width: 1250px) {
  .footer {
    width: 100%;
    padding: 16px 20px;
  }
}

@media screen and (max-width: 428px) {
  .footer {
    margin-top: 40px;
  }
}